import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Home from "../pages/User/Home";
// import User from "../pages/User/User";
// import Org from "../pages/Org/Org";
// import OrgAdd from "../pages/Org/OrgAdd";

import FadeTransitionRouter from "./FadeTransitionRouter";
import HeaderToolbar, { HeaderNotifier } from "./HeaderToolbar";
import HideOnScroll from "./HideOnScroll";
import { AppBarContext, AppMenuItem } from "../utils/appBarContext";

const User = lazy(() => import("../pages/User/User"));
const Org = lazy(() => import("../pages/Org/Org"));
const OrgAdd = lazy(() => import("../pages/Org/OrgAdd"));

const Root: React.FC = ({ children }) => {
  const appBarModelNotify: HeaderNotifier = {
    titleChanged: () => {},
    menuItemsChanged: () => {},
  };

  const appBarModel = {
    setTitle(title: string) {
      appBarModelNotify.titleChanged(title);
    },
    addMenuItems(items: AppMenuItem[]) {
      appBarModelNotify.menuItemsChanged(items);
    },
  };

  return (
    <AppBarContext.Provider value={appBarModel}>
      <HideOnScroll>
        <AppBar>
          <HeaderToolbar notifier={appBarModelNotify} />
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {children}
    </AppBarContext.Provider>
  );
};

const App: React.FC = () => {
  return (
    <Root>
      <FadeTransitionRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/user/*' element={<User />} />
            <Route path='/add' element={<OrgAdd />} />
            <Route path=':orgId/*' element={<Org />} />
          </Routes>
        </Suspense>
      </FadeTransitionRouter>
    </Root>
  );
};

export default App;
